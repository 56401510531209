//PrimeNgConfig
export const elPrimeNgMessages = {
    "userName": "Όνομα ή email",
    "password": "Κωδικός",
    "rememberMe": "Θυμήσου με",
    "common.select": "Επιλογή...",
    "common.yes": "Ναι",
    "common.no": "Όχι",
    "common.insert": "Εισαγωγή",
    "common.delete": "Διαγραφή",
    "common.search": "Αναζήτηση",
    "common.download": "Λήψη αρχείου",
    "common.autofit": "Αυτόματη αναπροσαρμογή στηλών",
    "common.settings": "Ρυθμίσεις",
    "common.cancel": "Ακύρωση",
    "common.confirmation": "Επιβεβαίωση",
    "common.save": "Αποθήκευση",
    "common.saveAndExit": "Αποθ.& έξοδος",
    "common.about": "Σχετικά",
    "common.warning": "Προειδοποίηση",
    "common.checkAll": "Επιλογή όλων",
    "common.itemsSelected": "επιλεγμένα στοιχεία.",
    "common.filterExist": "Ενεργό φίλτρο σε λειτουργία.",

    "message.successfullyCompleted": "Ολοκληρώθηκε με επιτυχία.",
    "message.deletionCompleted": "Η διαγραφή ολοκληρώθηκε με επιτυχία.",
    "message.insertionCompleted": "Η εισαγωγή ολοκληρώθηκε με επιτυχία.",
    "message.deleteSelection": "Έχετε επιλέξει {0} εγγραφές προς διαγραφή!",
    "message.canDeactivate": "Έχετε μη αποθηκευμένες αλλαγές. Να γίνει αποθήκευση;",
    "message.successfullySaved": "Τα δεδομένα αποθηκεύτηκαν με επιτυχία.",
    "message.failedToDelete": "Αποτυχία προσπάθειας διαγραφής.",
};

// Custom Messages
export const elCustomMessages = {
    "common.select": "Επιλογή...",
    "common.yes": "Ναι",
    "common.no": "Όχι",
    "common.insert": "Εισαγωγή",
    "common.delete": "Διαγραφή",
    "common.search": "Αναζήτηση",
    "common.autofit": "Αυτόματη αναπροσαρμογή στηλών",
    "common.cancel": "Ακύρωση",
    "common.save": "Αποθήκευση",
    "common.saveAndExit": "Αποθ.& έξοδος",
    "common.about": "Σχετικά",
    "common.warning": "Προειδοποίηση",
    "common.confirmation": "Επιβεβαίωση",
    "common.areYouSure": "Είστε σίγουροι?",
    "common.year": "Έτος",

    "message.successfullyCompleted": "Ολοκληρώθηκε με επιτυχία.",
    "message.deletionCompleted": "Η διαγραφή ολοκληρώθηκε με επιτυχία.",
    "message.insertionCompleted": "Η εισαγωγή ολοκληρώθηκε με επιτυχία.",
    "message.canDeactivate": "Έχετε μη αποθηκευμένες αλλαγές. Να γίνει αποθήκευση;",
    "message.successfullySaved": "Τα δεδομένα αποθηκεύτηκαν με επιτυχία.",

    // common
    "common.companyName": "Serefidis & Partners",
    "common.companySubName": "Φοροτεχνικές οικονομικές υπηρεσίες",
    "common.login": "Είσοδος",
    "common.signIn": "Σύνδεση",
    "common.logout": "Αποσύνδεση",
    "common.rememberMe": "Θυμήσου με",
    "common.office": "Γραφείο",
    "common.home": "Αρχική",
    "common.greek": "Ελληνικά",
    "common.english": "Αγγλικά",
    'common.noRecords': 'Δεν υπάρχουν διαθέσιμες εγγραφές.',
    "common.importResources": "Εισαγωγή λεκτικών",
    "common.create": "Δημιουργία",
    "common.modify": "Μεταβολή",
    "common.refresh": "Ανανέωση",
    "common.clearAll": "Διαγραφή όλα",
    "common.view": "Προβολή",
    "common.from": "Από",
    "common.to": "Εώς",
    "common.type": "Τύπος",
    "common.kind": "Είδος",
    "common.searchUser": "Αναζήτηση χρήστη",
    "common.searchPermission": "Αναζ.δικαίωμα χρήσης",
    "common.permissions": "Δικαιώματα χρήσης",
    "common.autoInsert": "Αυτόματη εισαγωγή",
    "common.retrieve": "Άντληση",
    "common.submited": "Υποβολή",
    "common.trader": "Συναλλασσόμενος",
    "common.employers": "Εργοδότες",
    "common.calc": "Υπολογισμός",
    "common.check": "Έλεγχος",
    "common.inform": "Ενημέρωση",
    "common.period": "Περίοδος",
    "common.register": "Εγγραφή",
    "common.collapse": "Σύμπτυξη",
    "common.expand": "Ανάπτυξη",
    "common.actions": "Ενέργειες",
    "common.payroll": "Μισθοδοσία",
    "common.saveState": "Αποθήκευση όψης",
    "common.removeState": "Διαγραφή όψης",
    "common.saveStateFilter": "Εφαρμογή φίλτρου",
    "common.removeStateFilter": "Ακύρωση φίλτρου",
    "common.degree": "βάθμιοι",
    "common.nonstop": "Συνεχόμενο",
    "common.split": "Διακεκομμένο",
    "common.overtime": "Υπερωρία",
    "common.total": "Άθροισμα",
    "common.min": "Μικρότερο",
    "common.max": "Μεγαλύτερο",
    "common.average": "Μέσος όρος",
    "common.grandTotal": "Γενικό άθροισμα",
    "common.identityPayment": "Ταυτ.Οφειλών",
    "common.hireDate": "Ημ.Πρόσληψης",
    "common.aade": "ΑΑΔΕ",
    "common.ika": "ΙΚΑ",
    "common.oaee": "ΟΑΕΕ",
    "common.efka": "ΕΦΚΑ",
    "common.keao": "ΚΕΑΟ",
    "common.aggregates": "Σύνολα",
    //grid
    "grid.footerSelected": "Έχετε επιλέξει {0} στοιχεία.",
    //message
    "message.noAction": "Καμμία ενέργεια.",
    "message.loggerInfo": "Παρακαλώ ελενξτε το αρχείο καταγραφής.",
    "message.internalServerError": "Εσωτερικό σφάλμα διακομιστή.",
    "message.invalidUrl": "Λανθασμένη διεύθυνση url.",
    "message.deleteConfirmation": "Επιβεβαίωση διαγραφής",
    "message.inactiveMultipleDeletes": "Η λειτουργία διαγραφής πολλαπλών εγγραφών είναι απενεργοποιημένη.",
    "message.hasSelections": "Επιλέξτε τουλάχιστον μία εγγραφή.",
    "message.transferCompleted": "Η μεταφορά ολοκληρώθηκε με επιτυχία.",
    "message.modifyCompleted": "Η μεταβολή ολοκληρώθηκε με επιτυχία.",
    "message.sendScheduleCompleted": "Το πρόγραμμα ωραρίων των εργαζομένων εστάλει με επιτυχία.",
    "message.unsavedChanges": "Έχετε μη αποθηκευμένες αλλαγές.",
    "message.successfullySubmited": "Η υποβολή έγινε με επιτυχία.",
    "message.sendEmailValidation": "Εστάλει email για επιβεβαίωση λογαριασμού.",
    "message.sendEmailPasswordRecovery": "Εστάλει email για αλλαγή κωδικού πρόσβασης.",
    "message.sendEmailSuccess": "Η αποστολή email ολοκληρώθηκε με επιτυχία.",
    "message.changeEmailSuccess": "Το email χρήστη άλλαξε με επιτυχία.",
    "message.testEmailSuccess": "Το email δοκιμής εστάλει με επιτυχία.",
    "message.sendInfoMessage": "Το μύνημα εστάλει.",
    "message.changePasswordSuccess": "Ο κωδικός πρόσβασης άλλαξε με επιτυχία.",
    "message.invalidFormValidation": "Μη έγκυρη επικύρωση φόρμας.",
    "message.invalidVat": "Λανθασμένο ΑΦΜ",
    "message.contractChange": " <div>Επιχειρείται αλλαγή σύμβασης στους κάτωθι εργαζόμενους: <br/> <br/> {0}  <br/> <br/> Θέλετε να προβείτε σε ενημέρωση; <div/>",
    "message.invalidYear": "Οι ημερομηνίες έχουν διαφορετική χρονιά",
    "message.invalidMonth": "Ο μηνας 'Από' πρεπει να ειναι μικρότερος ή ίσος απο τον μήνα 'Εώς'",
    "message.traderNotEmpty": "Το πεδίο 'Συναλλασσόμενος' δεν πρέπει να είναι κενό.",
    "message.periodNotEmpty": "Το πεδίο 'Περίοδος' δεν πρέπει να είναι κενό.",
    "message.checkConnection": "Ο έλεγχος ολοκληρώθηκε με επιτυχία.",
    "message.processCompleted": "Η διαδικασία ολοκληρώθηκε.",

    //payroll
    "payroll.employeeInfo": "Στοιχεία εργαζόμενου",
    "payroll.agreedSalary": "Συμφωνηθέντα",
    "payroll.legalSalary": "Νόμιμα",

    //trader
    "trader.fromRegistry": "Άντληση από το μητρώο",
    "trader.fromSrf": "Άντληση από το Srf",
    "trader.fromTaxSystem": "Άντληση από το TaxSystem",
    "trader.importKadTaxisNet": "Άντληση ΚΑΔ από TaxisNet",
    "trader.importKadBranchesTaxisNet": "Άντληση Υποκαταστημάτων & ΚΑΔ από TaxisNet",
    "trader.importTraderBoardMember": "Άντληση Δοικούντων από TaxisNet",
    "trader.checkConnection": "Έλεγχος σύνδεσης SoftOne",
    "trader.traderInfo": "Πληροφορίες συναλλασσόμενου",
    "trader.traderKads": "ΚΑΔ από TaxisNet",
    "trader.traderBranches": "Υποκαταστήματα από TaxisNet",
    "trader.boardMember": "Μέλη ΔΣ - Διοικούντες",
    "trader.traderRelationships": "Σχέσεις νομικού προσώπου",
    "trader.traderMemberships": "Στοιχεία μελών νομικού προσώπου",
    "trader.efkaNonSalaried": "Οικ.Υποχρ.ΕΦΚΑ μη μισθωτών",
    "trader.traderRating": "Αξιολόγηση συναλλασσομένου",
    //error
    "error.error": "Σφάλμα",
    "error.downloadImageFailed": "Αποτυχία λήψης εικόνας.",
    "error.uploadSaveForm": "Η λήψη αρχείου προυποθέτει αποθήκευση πρώτα.",
    "error.validationFormError": "Τα πεδία της φόρμας περιέχουν σφάλματα.",
    "error.failedToScraping": "Αποτυχία scraping.",
    "error.accessDenied": "Δεν επιτρέπεται η άδεια πρόσβασης.",
    "error.internalServerError": "Εσωτερικό σφάλμα διακομιστή.",
    "error.vatValidation": "Το ΑΦΜ δεν είναι έγκυρο.",
    "error.adjustedLimit": "Το 'ΑΝΑΠΡΟΣΑΡΜΟΣΜΕΝΟ ΟΡΙΟ ΑΠΑΛΛΑΓΗΣ' πρέπει να είναι μεγαλύτερο ή ίσο του μηδενός.",
    "error.currentLimit": "Το 'ΥΠΟΛΟΙΠΟ ΟΡΙΟΥ ΣΕΙΡΑΣ' πρέπει να είναι μεγαλύτερο ή ίσο του μηδενός.",
    "error.dailyBreakError": "Απουσία διαλείμματος.",
    "error.dailyTotalHoursError": "Υπέρβαση 8 ωρών εργασίας.",
    "error.breakLimitError": "Όριο διαλείμματος.",
    "error.breakLimitFormat": "Το διαλειμμα {0} είναι μεγαλύτερο από το όριο διαλείματος {1}",
    "error.uniqueVatValidation": "Το ΑΦΜ χρησιμοποείται ήδη.",
    //menu 
    "menu.catalog": "Κατάλογος",
    "menu.contentManagment": "Διαχ.περιεχομένου",
    "menu.traders": "Συναλλασσόμενοι",
    "menu.customers": "Πελάτες",
    "menu.users": "Χρήστες",
    "menu.configuration": "Παράμετροι",
    "menu.settings": "Ρυθμίσεις",
    "menu.system": "Σύστημα",
    "menu.environments": "Περιβάλλον",
    "menu.customerRoles": "Ρόλοι χρηστών",
    "menu.security": "Ασφάλεια",
    "menu.securityPermissions": "Δικαιώματα ασφαλείας",
    "menu.permissions": "Δικαιώματα χρήσης",
    "menu.customerPermissions": "Δικαιώματα χρήσης χρηστών",
    "menu.customerOnline": "Online χρήστες",
    "menu.logs": "Αρχείο καταγραφής",
    "menu.messages": "Μηνύματα",
    "menu.emailAccounts": "Λογαρισμοί email",
    "menu.news": "Νέα",
    "menu.blogs": "Μπλογκ",
    "menu.posts": "Αναρτήσεις",
    "menu.forums": "Φόρουμ",
    "menu.countries": "Χώρες",
    "menu.languages": "Γλώσσες",
    "menu.currencies": "Νομίσματα",
    "menu.scheduleTasks": "Προγραμματισμένες εργασίες",
    "menu.queuedEmail": "Emails για παράδοση",
    "menu.office": "Διαχείριση γραφείου",
    "menu.employees": "Υπάλληλοι",
    "menu.educations": "Εκπαίδευση",
    "menu.departments": "Τμήματα",
    "menu.jobTitles": "Τίτλοι εργασίας",
    "menu.specialties": "Ειδικότητες",
    "menu.chambers": "Επιμελητήρια",
    "menu.workingAreas": "Περιοχές εργασίας",
    "menu.accountingWorks": "Λογιστικές υπηρεσίες",
    "menu.traderGroup": "Ομαδοποίηση συναλλασσομένων",
    "menu.periodicityItems": "Κονδύλια περιοδικότητας",
    "menu.accounting": "Λογιστική",
    "menu.tax": "Φοροτεχνικά",
    "menu.payroll": "Μισθοδοσία",
    "menu.apdSubmission": "Υποβολές ΑΠΔ",
    "menu.fmySubmission": "Υποβολές ΦΜΥ",
    "menu.submissions": "Υποβολές",
    "menu.financially": "Χρηματοοικονομικά",
    "menu.numericalIndicators": "Αριθμοδείκτες",
    "menu.payoffLiabilities": "Ταχύτητα εξώφλησης βραχυπρόθεσμων υποχρεώσεων",
    "menu.monthlyFinancialBulletin": "Μηνιαίο οικονομικό δελτίο Γ'κατηγορίας",
    "menu.cashAvailable": "Χρηματικά διαθέσιμα",
    "menu.documents": "Έντυπα",
    "menu.vatExemptionApproval": "Εγκρ.Απαλλαγής ΦΠΑ",
    "menu.vatExemptionReport": "Γνωστοποιήσεις",
    "menu.vatExemptionSerial": "Σειρές",
    "menu.vatExemptionDoc": "Δ.Απαλλαγής ΦΠΑ",
    "menu.employeesManager": "Υπεύθυνοι διαχείρισης",
    "menu.vatCalculation": "Υπολογισμός ΦΠΑ",
    "menu.countingDocument": "Καταμέτρηση παραστατικών",
    "menu.aggregateAnalysis": "Συγκεντρωτική ανάλυση",
    "menu.administratively": "Διοικητικά",
    "menu.crm": "CRM",
    "menu.simpleTaskCategories": "Κατηγορίες",
    "menu.simpleTaskDepartments": "Τμήματα",
    "menu.simpleTaskNatures": "Φύση αντικειμένων",
    "menu.simpleTaskSectors": "Τομείς",
    "menu.simpleTaskManagers": "Διαχείρηση εργασιών",
    "menu.especially": "Ειδικά",
    "menu.projects": "Λίστα έργων",
    "menu.viewProjects": "Εμφάνιση έργων",
    "menu.projectsStatistic": "Στατιστικά έργων",
    "menu.workerSchedules": "Ωράρια",
    "menu.workerScheduleByEmployee": "Υποβολή ωραρίων",
    "menu.workerScheduleByTrader": "Υποβολή ωραρίων",
    "menu.workerScheduleShiftByTrader": "Βάρδιες",
    "menu.workerScheduleLog": "Ιστορικό ενημέρωσης",
    "menu.workerSchedulePending": "Εκκρεμότητες ωραρίων",
    "menu.intrastat": "Intrastat - Vies",
    "menu.listingF4": "Ενδ.παραδόσεις Φ4",
    "menu.listingF5": "Ενδ.αποκτήσεις Φ5",
    "menu.electronicTransmisson": "Ηλεκτρονική διαβίβαση",
    "menu.eSend": "Ε-Send",
    "menu.bankingTransactions": "Τραπεζικές συναλλαγές",
    "menu.piraeusTransactions": "Τραπ.συναλλαγές Πειραιώς",
    "menu.workerCatalog": "Λίστα εργαζομένων",
    "menu.periodic-f2": "Περιοδική ΦΠΑ",
    "menu.assignment": "Εντολές",
    "menu.assignmentReason": "Κατηγορίες εντολών",
    "menu.assignmentTask": "Εντολές ανάθεσης",
    "menu.assignmentTaskAction": "Ενέργειες υπεύθυνου",
    "menu.assignmentPrototype": "Πρότυπες εντολές",
    "menu.assignmentPrototypeAction": "Πρότυπες ενέργειες",
    "menu.bookmark": "Σελιδοδείκτες",
    "menu.taxFactor": "Φορολογικές κλίμακες",
    "menu.workerSickLeave": "Υπόλοιπο ασθενειών",
    "menu.workerLeave": "Υπόλοιπο αδειών",
    "menu.workerLeaves": "Άδειες",
    "menu.workerLeaveDetail": "Ενημέρωση αδειών",
    "menu.obligations": "Υποχρεώσεις",
    "menu.financialObligation": "Οικονομικές υποχρεώσεις",
    "menu.emailMessage": "Διαχείρηση emails",
    "menu.activityLog": "Καταγραφή δραστηριοτήτων",
    "menu.activityLogType": "Τύποι δραστηριοτήτων",
    "menu.customerActivity": "Καταγραφή δραστηριοτήτων",
    "menu.apdContribution": "Εισφορές ΑΠΔ",
    "menu.fmyContribution": "Εισφορές ΦΜΥ",
    "menu.payrollStatus": "Μισθοδοτική κατάσταση",
    "menu.employeeCostCalculation": "Υπολογισμός κόστους εργαζόμενου",
    "menu.monthlyBCategoryBulletin": "Μηνιαίο οικονομικό δελτίο Β'κατηγορίας",

    "menu.createEmailMessages": "Δημιουργία email Οικ.Υποχρ.τρέχουσας περιόδου",
    "menu.createSelectedEmailMessages": "Δημιουργία email επιλεγμένων εγγραφών",
    "menu.sendFinancialObligationEmails": "Αποστολή email Οικ.Υποχρ.τρέχουσας περιόδου",
    "menu.sendSelectedEmails": "Αποστολή επιλεγμένων email",
    "menu.undoTraderDeletion": "Αναίρεση διαγραφής",
    "menu.importPayrollIds": "Άντληση HyperM-ID",
    "menu.importKeaoGredentials": "Άντληση κωδ.σύνδεσης ΚΕΑΟ",
    "menu.importMyDataCredentials": "Άντληση κωδ.σύνδεσης MyData",
    "menu.createEmailInfoFhmPos": "Δημιουργία ενημερωτικού email για ΦΗΜ-POS",

    "menu.myData": "MyData",
    "menu.myDataItem": "Εγγραφές myData",
    "menu.vatTransference": "Μετακύλιση ΦΠΑ",
    "menu.traderActivityLog": "Δραστηριότητα συναλλασσομένων",
    "menu.employeeActivityLog": "Δραστηριότητα υπαλλήλων",

    "menu.traderRating": "Αξιολόγηση συν/νου",
    "menu.traderRatingGravity": "Αξ.Βάρος",
    "menu.traderRatingCategory": "Αξ.Κατηγορίες",
    "menu.traderRatingByEmployee": "Αξ.βάση υπεύθυνου",
    "menu.traderRatingByDepartment": "Αξ.βάση τμήματος",
    "menu.traderRatingByTrader": "Αξ.βάση συναλ/νου",
    "menu.payrollCheck": "Έλεγχος άρθρων",
    "menu.intertemporalC": "Διαχρονική εικόνα επιχείρησης Γ'Κατηγ.",
    "menu.intertemporalB": "Διαχρονική εικόνα επιχείρησης Β'Κατηγ.",

    //any
    "title.listingF4": "Ανακεφαλαιωτικός πίνακας πωλήσεων",
    "title.listingF5": "Ανακεφαλαιωτικός πίνακας αγορών",

    //any
    "query.1.Cash": "Μετρητά",
    "query.2.Bank": "Τράπεζες",
    "query.1.Sales": "Πωλήσεις",
    "query.2.Purchases": "Αγορές",
    "query.3.Expenses": "Δαπάνες",

    //lookups 
    "lookup.levels": [
        { value: 6, label: "6-βάθμιοι (όλοι)" },
        { value: 5, label: "5-βάθμιοι" },
        { value: 4, label: "4-βάθμιοι" },
        { value: 3, label: "3-βάθμιοι" },
        { value: 2, label: "2-βάθμιοι" },
        { value: 1, label: "1-βάθμιοι" }
    ],
    "lookup.f523": [
        { label: "Όχι", value: 0 },
        { label: "Ναι", value: 1 },
    ],
    "form.bCategoryMessage": "*Το σενάριο φορολογίας τελεί υπό την αίρεση πιθανών άλλων εισοδημάτων που επηρεάζουν τη φορολογία.",

};

// Kendo Angular Component Messages
export const elComponentMessages = {
    // notification
    'kendo.notification.closeTitle': 'xxx',
    //TreeList
    'kendo.treelist.noRecords': 'Δεν υπάρχουν διαθέσιμες εγγραφές.',

    // Grid
    'kendo.grid.groupPanelEmpty': 'Σύρετε μια κεφαλίδα στήλης και αποθέστε την εδώ για ομαδοποίηση ανά στήλη',
    'kendo.grid.noRecords': 'Δεν υπάρχουν διαθέσιμες εγγραφές.',
    'kendo.grid.pagerFirstPage': 'Μετάβαση στην πρώτη σελίδα',
    'kendo.grid.pagerPreviousPage': 'Μετάβαση στην προηγούμενη σελίδα',
    'kendo.grid.pagerNextPage': 'Μετάβαση στην επόμενη σελίδα',
    'kendo.grid.pagerLastPage': 'Μετάβαση στην τελευταία σελίδα',
    'kendo.grid.pagerPage': 'Σελίδα',
    'kendo.grid.pagerOf': 'από',
    'kendo.grid.pagerItems': 'στοιχεία',
    'kendo.grid.pagerItemsPerPage': 'στοιχεία ανά σελίδα',
    'kendo.grid.pagerPageNumberInputTitle': 'Αριθμός σελίδας',
    'kendo.grid.filter': 'Φίλτρο',
    'kendo.grid.filterEqOperator': 'Ίσο με',
    'kendo.grid.filterNotEqOperator': 'Δεν ισούται με',
    'kendo.grid.filterIsNullOperator': 'Είναι null',
    'kendo.grid.filterIsNotNullOperator': 'Δεν είναι null',
    'kendo.grid.filterIsEmptyOperator': 'Είναι κενό',
    'kendo.grid.filterIsNotEmptyOperator': 'Δεν είναι κενό',
    'kendo.grid.filterStartsWithOperator': 'Ξεκινά με',
    'kendo.grid.filterContainsOperator': 'Περιέχει',
    'kendo.grid.filterNotContainsOperator': 'Δεν περιέχει',
    'kendo.grid.filterEndsWithOperator': 'Τελειώνει με',
    'kendo.grid.filterGteOperator': 'Είναι μεγαλύτερο ή ίσο με',
    'kendo.grid.filterGtOperator': 'Είναι μεγαλύτερο από',
    'kendo.grid.filterLteOperator': 'Είναι μικρότερο ή ίσο με',
    'kendo.grid.filterLtOperator': 'Είναι μικρότερο από',
    'kendo.grid.filterIsTrue': 'Αληθές',
    'kendo.grid.filterIsFalse': 'Ψευδές',
    'kendo.grid.filterBooleanAll': '(Όλα)',
    'kendo.grid.filterAfterOrEqualOperator': 'Είναι μετά ή ίσο με',
    'kendo.grid.filterAfterOperator': 'Είναι μετά',
    'kendo.grid.filterBeforeOperator': 'Είναι πριν',
    'kendo.grid.filterBeforeOrEqualOperator': 'Είναι πριν ή ίσο με',
    'kendo.grid.filterFilterButton': 'Φίλτρο',
    'kendo.grid.filterClearButton': 'Καθαρισμός',
    'kendo.grid.filterAndLogic': 'και',
    'kendo.grid.filterOrLogic': 'ή',
    'kendo.grid.filterDateToggle': 'Εναλλαγή ημερολογίου',
    'kendo.grid.filterDateToday': 'Σήμερα',
    'kendo.grid.filterNumericDecrement': 'Μείωση τιμής',
    'kendo.grid.filterNumericIncrement': 'Αύξηση τιμής',
    'kendo.grid.loading': 'Φόρτωση',
    'kendo.grid.sort': 'Ταξινόμηση',
    'kendo.grid.columnMenu': 'Μενού στήλης',
    'kendo.grid.columns': 'Στήλες',
    'kendo.grid.lock': 'Κλείδωμα',
    'kendo.grid.unlock': 'Ξεκλείδωμα',
    'kendo.grid.stick': 'Κόλλησε',
    'kendo.grid.unstick': 'Ξεκόλλησε',
    'kendo.grid.setColumnPosition': 'Ορισμός θέσης στήλης',
    'kendo.grid.sortAscending': 'Αύξουσα ταξινόμηση',
    'kendo.grid.sortDescending': 'Φθίνουσα ταξινόμηση',
    'kendo.grid.columnsApply': 'Εφαρμογή',
    'kendo.grid.columnsReset': 'Επαναφορά',
    'kendo.grid.sortable': 'Δυνατότητα ταξινόμησης',
    'kendo.grid.sortedAscending': 'Ταξινομημένη αύξουσα',
    'kendo.grid.sortedDescending': 'Ταξινομημένη φθίνουσα',
    'kendo.grid.sortedDefault': 'Μη ταξινομημένο',

    // DropDowns
    'kendo.autocomplete.noDataText': 'Δεν βρέθηκαν δεδομένα',
    'kendo.autocomplete.clearTitle': 'Καθαρισμός τίτλου',
    'kendo.combobox.noDataText': 'Δεν βρέθηκαν δεδομένα',
    'kendo.combobox.clearTitle': 'Καθαρισμός τίτλου',
    'kendo.dropdownlist.noDataText': 'Δεν βρέθηκαν δεδομένα',
    'kendo.dropdownlist.clearTitle': 'Διαγραφή',

    //multiselecttree
    "kendo.multiselecttree.noDataText": "Δεν βρέθηκαν δεδομένα",
    "kendo.multiselecttree.clearTitle": "Καθαρισμός",
    "kendo.multiselecttree.checkAllText": "Επιλογή όλων",
    "kendo.multiselecttree.selectButtonText": "Επιλογή",
    "kendo.multiselecttree.filterInputLabel": "Φίλτρο",
    "kendo.multiselecttree.popupLabel": "Λίστα επιλογών",
    "kendo.multiselecttree.applyButton": "Εντάξει",
    "kendo.multiselecttree.cancelButton": "Άκυρο",

    // Calendar
    'kendo.calendar.today': 'Σήμερα',

    // TextBox
    'kendo.textbox.clearTitle': 'Καθαρισμός τίτλου',

    // DatePicker
    'kendo.datepicker.today': 'Σήμερα',
    'kendo.datepicker.toggle': 'Εναλλαγή ημερολογίου',

    // DateInput
    'kendo.dateinput.increment': 'Αύξηση τιμής',
    'kendo.dateinput.decrement': 'Μείωση τιμής',

    // NumericTextBox
    'kendo.numerictextbox.increment': 'Αύξηση τιμής',
    'kendo.numerictextbox.decrement': 'Μείωση τιμής',

    // DateTimePicker
    'kendo.datetimepicker.dateTab': 'Ημερομηνία',
    'kendo.datetimepicker.dateTabLabel': 'Καρτέλα ημερομηνία',
    'kendo.datetimepicker.timeTab': 'Ώρα',
    'kendo.datetimepicker.timeTabLabel': 'Καρτέλα ώρας',
    'kendo.datetimepicker.toggle': 'Εναλλαγή αναδυόμενου παραθύρου',
    'kendo.datetimepicker.accept': 'Αποδοχή',
    'kendo.datetimepicker.acceptLabel': 'Αποδοχή',
    'kendo.datetimepicker.cancel': 'Ακύρωση',
    'kendo.datetimepicker.cancelLabel': 'Ακύρωση',
    'kendo.datetimepicker.now': 'Τώρα',
    'kendo.datetimepicker.nowLabel': 'Επιλέξτε τώρα',
    'kendo.datetimepicker.today': 'Σήμερα',
    'kendo.datetimepicker.prevButtonTitle': 'Πλοήγηση στην προηγούμενη προβολή',
    'kendo.datetimepicker.nextButtonTitle': 'Πλοήγηση στην επόμενη προβολή',

    // TimePicker
    'kendo.timepicker.accept': 'Αποδοχή',
    'kendo.timepicker.acceptLabel': 'Αποδοχή',
    'kendo.timepicker.cancel': 'Ακύρωση',
    'kendo.timepicker.cancelLabel': 'Ακύρωση',
    'kendo.timepicker.now': 'Τώρα',
    'kendo.timepicker.nowLabel': 'Τώρα',
    'kendo.timepicker.toggle': 'Εναλλαγή λίστας ώρας',

    // FileSelect
    'kendo.fileselect.cancel': 'Ακύρωση',
    'kendo.fileselect.clearSelectedFiles': 'Καθαρισμός',
    'kendo.fileselect.dropFilesHere': 'Αποθέστε αρχεία εδώ για μεταφόρτωση',
    'kendo.fileselect.externalDropFilesHere': 'Σύρετε και αποθέστε αρχεία εδώ για μεταφόρτωση',
    'kendo.fileselect.filesBatchStatus': 'αρχεία',
    'kendo.fileselect.filesBatchStatusFailed': 'αποτυχία μεταφόρτωσης αρχείων.',
    'kendo.fileselect.filesBatchStatusUploaded': 'τα αρχεία μεταφορτώθηκαν με επιτυχία.',
    'kendo.fileselect.fileStatusFailed': 'Απέτυχε η μεταφόρτωση του αρχείου.',
    'kendo.fileselect.fileStatusUploaded': 'Το αρχείο μεταφορτώθηκε με επιτυχία.',
    'kendo.fileselect.headerStatusPaused': 'Σε παύση',
    'kendo.fileselect.headerStatusUploaded': 'Τέλος',
    'kendo.fileselect.headerStatusUploading': 'Μεταφόρτωση...',
    'kendo.fileselect.invalidFileExtension': 'Δεν επιτρέπεται ο τύπος αρχείου.',
    'kendo.fileselect.invalidMaxFileSize': 'Το μέγεθος αρχείου είναι πολύ μεγάλο.',
    'kendo.fileselect.invalidMinFileSize': 'Το μέγεθος αρχείου είναι πολύ μικρό.',
    'kendo.fileselect.remove': 'Κατάργηση',
    'kendo.fileselect.retry': 'Επανάληψη',
    'kendo.fileselect.select': 'Επιλογή αρχείων...',
    'kendo.fileselect.uploadSelectedFiles': 'Μεταφόρτωση αρχείων',

    // Upload
    'kendo.upload.cancel': 'Ακύρωση',
    'kendo.upload.clearSelectedFiles': 'Καθαρισμός',
    'kendo.upload.dropFilesHere': 'Μεταφόρτωση',
    'kendo.upload.externalDropFilesHere': 'Σύρετε και αποθέστε αρχεία εδώ για μεταφόρτωση',
    'kendo.upload.filesBatchStatus': 'αρχεία',
    'kendo.upload.filesBatchStatusFailed': 'Απέτυχε η μεταφόρτωση των αρχείων.',
    'kendo.upload.filesBatchStatusUploaded': 'τα αρχεία μεταφορτώθηκαν με επιτυχία.',
    'kendo.upload.fileStatusFailed': 'Απέτυχε η μεταφόρτωση του αρχείου.',
    'kendo.upload.fileStatusUploaded': 'Το αρχείο μεταφορτώθηκε με επιτυχία.',
    'kendo.upload.headerStatusPaused': 'Σε παύση',
    'kendo.upload.headerStatusUploaded': 'Ολοκληρώθηκε',
    'kendo.upload.headerStatusUploading': 'Μεταφόρτωση...',
    'kendo.upload.invalidFileExtension': 'Δεν επιτρέπεται ο τύπος αρχείου.',
    'kendo.upload.invalidMaxFileSize': 'Το μέγεθος αρχείου είναι πολύ μεγάλο.',
    'kendo.upload.invalidMinFileSize': 'Το μέγεθος αρχείου είναι πολύ μικρό.',
    'kendo.upload.pause': 'Παύση',
    'kendo.upload.remove': 'Κατάργηση',
    'kendo.upload.resume': 'Συνέχιση',
    'kendo.upload.retry': 'Επανάληψη',
    'kendo.upload.select': 'Επιλογή αρχείου...',
    'kendo.upload.uploadSelectedFiles': 'Μεταφόρτωση αρχείου',

    // Scheduler
    "kendo.scheduler.agendaViewTitle": "Ατζέντα",
    "kendo.scheduler.allDay": "όλη την ημέρα",
    "kendo.scheduler.allEvents": "Όλα τα συμβάντα",
    "kendo.scheduler.calendarToday": "Σήμερα",
    "kendo.scheduler.cancel": "Ακύρωση",
    "kendo.scheduler.save": "Αποθήκευση",
    "kendo.scheduler.editorEventTitle": "Τίτλος",
    "kendo.scheduler.editorEventStart": "Έναρξη",
    "kendo.scheduler.editorEventStartTimeZone": "Ζώνη ώρας αρχή",
    "kendo.scheduler.editorEventEnd": "Τέλος",
    "kendo.scheduler.editorEventEndTimeZone": "Ζώνη ώρας λήξης",
    "kendo.scheduler.dateHeader": "Ημερομηνία",
    "kendo.scheduler.dayViewTitle": "Ημέρα",
    "kendo.scheduler.deleteConfirmation": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το συμβάν;",
    "kendo.scheduler.deleteDialogTitle": "Διαγραφή συμβάντος",
    "kendo.scheduler.deleteOccurrence": "Διαγραφή τρέχοντος περιστατικού",
    "kendo.scheduler.deleteRecurringConfirmation": "Θέλετε να διαγράψετε μόνο αυτήν την εμφάνιση συμβάντος ή ολόκληρη τη σειρά;",
    "kendo.scheduler.deleteRecurringDialogTitle": "Διαγραφή επαναλαμβανόμενου στοιχείου",
    "kendo.scheduler.deleteSeries": "Διαγραφή της σειράς",
    "kendo.scheduler.deleteTitle": "Διαγραφή",
    "kendo.scheduler.destroy": "Διαγραφή",
    "kendo.scheduler.editOccurrence": "Επεξεργασία τρέχουσας εμφάνισης",
    "kendo.scheduler.editorEventAllDay": "Ολοήμερη εκδήλωση",
    "kendo.scheduler.editorEventDescription": "Περιγραφή",
    "kendo.scheduler.editorEventSeparateTimeZones": "Τέλος σε διαφορετική ζώνη ώρας",
    "kendo.scheduler.editorEventTimeZone": "Καθορισμός ζώνης ώρας",
    "kendo.scheduler.editorTitle": "Εκδήλωση",
    "kendo.scheduler.editRecurringConfirmation": "Θέλετε να επεξεργαστείτε μόνο αυτήν την εμφάνιση συμβάντος ή ολόκληρη τη σειρά;",
    "kendo.scheduler.editRecurringDialogTitle": "Επεξεργασία επαναλαμβανόμενου στοιχείου",
    "kendo.scheduler.editSeries": "Επεξεργασία της σειράς",
    "kendo.scheduler.eventHeader": "Εκδήλωση",
    "kendo.scheduler.monthViewTitle": "Month",
    "kendo.scheduler.multiDayViewTitle": "Multi-Day",
    "kendo.scheduler.nextTitle": "Επόμενο",
    "kendo.scheduler.previousTitle": "Προηγούμενο",
    "kendo.scheduler.recurenceEditorDailyInterval": "ημέρες",
    "kendo.scheduler.recurrenceEditorDailyRepeatEvery": "Επανάληψη κάθε",
    "kendo.scheduler.recurrenceEditorEndAfter": "Μετά",
    "kendo.scheduler.recurrenceEditorEndLabel": "Τέλος",
    "kendo.scheduler.recurrenceEditorEndNever": "Ποτέ",
    "kendo.scheduler.recurrenceEditorEndOccurrence": "εμφάνιση(ες)",
    "kendo.scheduler.recurrenceEditorEndOn": "Ενεργό",
    "kendo.scheduler.recurrenceEditorFrequenciesDaily": "Καθημερινά",
    "kendo.scheduler.recurrenceEditorFrequenciesMonthly": "Μηνιαία",
    "kendo.scheduler.recurrenceEditorFrequenciesNever": "Ποτέ",
    "kendo.scheduler.recurrenceEditorFrequenciesWeekly": "Εβδομαδιαία",
    "kendo.scheduler.recurrenceEditorFrequenciesYearly": "Ετήσια",
    "kendo.scheduler.recurrenceEditorMonthlyDay": "Ημέρα",
    "kendo.scheduler.recurenceEditorMonthlyInterval": "μήνας",
    "kendo.scheduler.recurrenceEditorMonthlyRepeatEvery": "Επανάληψη κάθε",
    "kendo.scheduler.recurrenceEditorMonthlyRepeatOn": "Επανάληψη σε",
    "kendo.scheduler.recurrenceEditorOffsetPositionsFirst": "First",
    "kendo.scheduler.recurrenceEditorOffsetPositionsFourth": "Τέταρτο",
    "kendo.scheduler.recurrenceEditorOffsetPositionsLast": "Τελευταίο",
    "kendo.scheduler.recurrenceEditorOffsetPositionsSecond": "Δεύτερο",
    "kendo.scheduler.recurrenceEditorOffsetPositionsThird": "Τρίτο",
    "kendo.scheduler.recurrenceEditorRepeat": "Επανάληψη",
    "kendo.scheduler.recurrenceEditorWeekdaysDay": "Ημέρα",
    "kendo.scheduler.recurenceEditorWeekdaysWeekday": "Weekday",
    "kendo.scheduler.recurenceEditorWeekdaysWeekendday": "Weekend Day",
    "kendo.scheduler.recurenceEditorWeeklyInterval": "εβδομάδες",
    "kendo.scheduler.recurrenceEditorWeeklyRepeatEvery": "Επανάληψη κάθε",
    "kendo.scheduler.recurrenceEditorWeeklyRepeatOn": "Επανάληψη σε",
    "kendo.scheduler.recurenceEditorYearlyInterval": "έτος(α)",
    "kendo.scheduler.recurrenceEditorYearlyOf": "από",
    "kendo.scheduler.recurrenceEditorYearlyRepeatEvery": "Επανάληψη κάθε",
    "kendo.scheduler.recurrenceEditorYearlyRepeatOn": "Επανάληψη σε",
    "kendo.scheduler.showFullDay": "Εμφάνιση ολόκληρης ημέρας",
    "kendo.scheduler.showWorkDay": "Εμφάνιση ωραρίου λειτουργίας",
    "kendo.scheduler.timeHeader": "Χρονοδιάγραμμα",
    "kendo.scheduler.timelineMonthViewTitle": "Χρονοδιάγραμμα μηνός",
    "kendo.scheduler.timelineViewTitle": "Χρονολόγιο",
    "kendo.scheduler.timelineWeekViewTitle": "Χρονολόγιο εβδομάδας",
    "kendo.scheduler.today": "Σήμερα",
    "kendo.scheduler.weekViewTitle": "Εβδομάδα",
    "kendo.scheduler.workWeekViewTitle": "Εβδομάδα εργασίας",

    // RecurrenceEditor
    "kendo.recurrenceeditor.dailyInterval": "ημέρες",
    "kendo.recurrenceeditor.dailyRepeatEvery": "Επανάληψη κάθε",
    "kendo.recurrenceeditor.endAfter": "Μετά",
    "kendo.recurrenceeditor.endLabel": "Τέλος",
    "kendo.recurrenceeditor.endNever": "Ποτέ",
    "kendo.recurrenceeditor.endOccurrence": "εμφάνιση(ες)",
    "kendo.recurrenceeditor.endOn": "Ενεργό",
    "kendo.recurrenceeditor.frequenciesDaily": "Καθημερινά",
    "kendo.recurrenceeditor.frequenciesMonthly": "Μηνιαία",
    "kendo.recurrenceeditor.frequenciesNever": "Ποτέ",
    "kendo.recurrenceeditor.frequenciesWeekly": "Εβδομαδιαία",
    "kendo.recurrenceeditor.frequenciesYearly": "Ετήσια",
    "kendo.recurrenceeditor.monthlyDay": "Ημέρα",
    "kendo.recurrenceeditor.monthlyInterval": "μήνας",
    "kendo.recurrenceeditor.monthlyRepeatEvery": "Επανάληψη κάθε",
    "kendo.recurrenceeditor.monthlyRepeatOn": "Επανάληψη σε",
    "kendo.recurrenceeditor.offsetPositionsFourth": "Τέταρτο",
    "kendo.recurrenceeditor.offsetPositionsLast": "Τελευταίο",
    "kendo.recurrenceeditor.offsetPositionsSecond": "Δεύτερο",
    "kendo.recurrenceeditor.offsetPositionsThird": "Τρίτο",
    "kendo.recurrenceeditor.repeat": "Επανάληψη",
    "kendo.recurrenceeditor.weekdaysDay": "Ημέρα",
    "kendo.recurrenceeditor.weekdaysWeekday": "Καθημερινή",
    "kendo.recurrenceeditor.weekdaysWeekendday": "Καθημερινή ημέρα",
    "kendo.recurrenceeditor.weeklyInterval": "εβδομάδες",
    "kendo.recurrenceeditor.weeklyRepeatEvery": "Επανάληψη κάθε",
    "kendo.recurrenceeditor.weeklyRepeatOn": "Επανάληψη σε",
    "kendo.recurrenceeditor.yearlyInterval": "έτος(α)",
    "kendo.recurrenceeditor.yearlyOf": "από",
    "kendo.recurrenceeditor.yearlyRepeatEvery": "Επανάληψη κάθε",
    "kendo.recurrenceeditor.yearlyRepeatOn": "Επανάληψη σε",
};
